
export default function dateFormatter(unformedDate) {
    let date = new Date(unformedDate);
    let day = date.getDate();
    let month = date.getMonth();
    month = month + 1;
    if (day.toString().length === 1) {
        day = '0' + day;
    }
    if (month.toString().length === 1) {
        month = '0' + month;
    }
    return day + '.' + month + '.' + date.getFullYear();
}

export function getMondayOfDate(unformedDate) {
    let date = new Date(unformedDate);
    let day = date.getDay(),
        diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
}

export function getSundayOfDate(unformedDate) {
    let date = new Date(unformedDate);
    let day = date.getDay(),
        diff = date.getDate() + (7 - day);
    return new Date(date.setDate(diff));
}
export function getFirstDateOfMonthByDate(unformedDate) {
    let date = new Date(unformedDate);
    let month = date.getMonth();
    let year = date.getFullYear();
    return addDays(new Date(year, month, 1), 1);

}
export function getLastDateOfMonthByDate(unformedDate) {
    let date = new Date(unformedDate);
    let month = date.getMonth();
    let year = date.getFullYear();
    return addDays(new Date(year, month + 1, 0), 1);
}
export function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
export function addMonths(date, months) {
    let result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
}
export function addWeeks(date, weeks) {
    let result = new Date(date);
    result.setDate(result.getDate() + weeks * 7);
    return result;
}
